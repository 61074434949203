import React from 'react'
import Root from './containers/routes'
import 'scss/base/_common.scss'

function App () {
  return (
    <div className='App'>
      <Root />
    </div>
  )
}

export default App
