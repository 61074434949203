

// window.log({
//     action: 'testAction',
//     label: 'testLabel',
//     value: 10
// })q

window.log = payload => {
  const parsedVal = parseInt(payload.value, 10) // Send value from component only if it is a number!
  const sanitizedValue = isNaN(parsedVal) ? 0 : parsedVal
  if (window.ga) {
    console.info('GA'+payload.action, payload)
    window.ga(
      'send',
      'event',
      `rentkarma_web`,
      payload.action,
      payload.label,
      sanitizedValue,
      payload.ga_params
    )
  }
}
