import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import { browserHistory } from 'react-router'
const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './Home'))
const Owners = React.lazy(() =>
  import(/* webpackChunkName: "Owners" */ './Owners')
)
const Tenants = React.lazy(() =>
  import(/* webpackChunkName: "Tenants" */ './Tenants')
)
const Propertymanagers = React.lazy(() =>
  import(/* webpackChunkName: "Propertymanagers" */ './Propertymanagers')
)
const Faqs = React.lazy(() => import(/* webpackChunkName: "Faqs" */ './Faqs'))
const About = React.lazy(() =>
  import(/* webpackChunkName: "About" */ './About')
)
const Contact = React.lazy(() =>
  import(/* webpackChunkName: "Contact" */ './Contact')
)
const TenantProfile = React.lazy(() =>
  import(/* webpackChunkName: "TenantProfile" */ './TenantProfile')
)

// import fontRegular from '_assets/fonts/NeurialGrotesk-Regular.ttf'
// import fontMedium from '_assets/fonts/NeurialGrotesk-Medium.ttf'

const renderPageNotFound = () => <h1>Page not found</h1>

export default () => (
  <React.Fragment>
    <Router>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/ownerSearch' component={Home} exact />
          <Route path='/rentKarma' component={Home} exact />
          <Route path='/tenantProfile' component={Home} exact />
          <Route path='/pastTenants' component={Home} exact />
          <Route path='/myHome' component={Home} exact />
          <Route path='/myTenants' component={Home} exact />
          <Route path='/about' component={About} exact />
          <Route path='/contact' component={Contact} exact />
          <Route path='/owners' component={Owners} exact />
          <Route path='/tenants' component={Tenants} exact />
          <Route path='/propertymanagers' component={Propertymanagers} exact />
          <Route path='/faqs' component={Faqs} exact />
          <Route path='/profile/:id' component={TenantProfile} exact />
          <Route path='/user/:id' component={TenantProfile} exact />
          <Route path='/user/' component={TenantProfile} exact />
          <Route render={renderPageNotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  </React.Fragment>
)
